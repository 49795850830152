import Filter from "badwords-ko";
import LoadingSpinner from "../components/LoadingSpinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useMutation } from "@apollo/client";
import { CREATE_IMAGE } from "../graphQL/query";
import { negativeOptions, options, ratioOptions } from "../options";
import { NavLink } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-range-slider-input/dist/style.css';
import Logo from '../assets/svg/logo.svg'
import { ReactComponent as RefreshIcon } from '../assets/svg/icon_refresh.svg';
import { ReactComponent as ResetIcon } from '../assets/svg/icon_reset.svg';
import { ReactComponent as DownIcon } from '../assets/svg/icon_down.svg';
import { ReactComponent as DeleteIcon } from '../assets/svg/icon_delete.svg';
import { ReactComponent as DownloadIcon } from '../assets/svg/icon_download.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/icon_close_big.svg';
import { ReactComponent as PrevIcon } from '../assets/svg/icon_prev.svg';
import { ReactComponent as Check2Icon } from '../assets/svg/icon_check2.svg';
import { ReactComponent as XIcon } from '../assets/svg/icon_x.svg';
import { ReactComponent as CheckedIcon } from '../assets/svg/icon_checked.svg';
import { ReactComponent as UncheckedIcon } from '../assets/svg/icon_unchecked.svg';
import { ReactComponent as Spinner } from '../assets/svg/LoadingSpinner.svg';
import { korBadWords, engBadWords } from "../filtering";
import { useContext } from "react";
import { AppContext } from "../App";
import Sidebar from "../components/Sidebar";
import { useScrollLock } from "../hooks/useScrollLock";
import { convertBase64, downloadImage, getNow, isDBSupported } from "../utils/common";
import { recommendedImage } from "../recommendedImages";
import { useLogout } from "../hooks/useLogout";

function MobileMain() {

	const filter = new Filter({ placeHolder: " " });  // 비속어 필터
	filter.addWords(...korBadWords, ...engBadWords);

	const { isMenuOpen, setIsMenuOpen, isMobile, sessionID } = useContext(AppContext);
	const { logout } = useLogout();
	const { lockScroll, unlockScroll } = useScrollLock();
	const { add, getAll, deleteRecord } = useIndexedDB('album');  // indexed DB 옵션 (add: 추가, getAll: 목록 조회, deleteRecord: 삭제)

	const [promptTxt, setPromptTxt] = useState();  // 프롬프트 텍스트
	const [nPromptTxt, setNPromptTxt] = useState();  // 네거티브 프롬프트 텍스트
	const [promptVisible, setPromptVisible] = useState();  // 프롬프트 선택 모달 (p: 프롬프트, np: 네거티브 프롬프트)
	const [selectedOptions, setSelectedOptions] = useState([]);  // 프롬프트 옵션
	const [selectedNOptions, setSelectedNOptions] = useState([]);  // 네거티브 프롬프트 옵션

	const [detailVisible, setDetailVisible] = useState(false);  // 이미지 상세 모달
	const [selectedIdx, setSelectedIdx] = useState(null);  // 이미지 상세 정보

	const [modalVisible, setModalVisible] = useState(false);  // 이미지 생성결과 모달
	const openImageModal = (chk) => {
		if (chk) {
			setModalVisible(true);
			lockScroll();
		} else {
			setModalVisible(false);
			setImage();
			unlockScroll();
		}
	}

	const [album, setAlbum] = useState([]);  // 앨범 목록
	const [image, setImage] = useState();  // 생성된 이미지 배열(base64)
	const [myGallery, setMyGallery] = useState('hidden');  // true: 내 앨범, false: 추천 둘러보기, hidden: 숨김

	const [selectedRatio, setSelectedRatio] = useState('');  // 이미지 비율
	const [selectedStyle, setSelectedStyle] = useState({ key: 'default', style: 'default', name: '기본' });  // 이미지 스타일
	const [createOption, setCreateOption] = useState({
		userID: sessionStorage.getItem('potuid'),
		width: 512,
		height: 512,
		n_iter: 1,
		cfg_scale: 7,  // 프롬프트 이행도
		steps: 20,  // 연산횟수
		sampler_index: "Euler a",
		Log: {
			email: sessionStorage.getItem('potemail'),  // 로그인한 사용자 이메일
			action: 'create',
			menu: 'txt2img'
		},
	});



	/** 이미지 비율 셀렉트박스 선택 */
	const handleSelectChange = (option) => {
		let size = { width: option.width, height: option.height };
		if (option.width !== 520) size['n_iter'] = 1;  // 1:1 비율 아닐 경우, 이미지 1장 제한   
		setSelectedRatio(option.label);
		setCreateOption((createOption) => ({ ...createOption, ...size }))
	}

	/* 프롬프트 옵션 선택 */
	const handleClickOption = (checked, item, type) => {  // type : p === 프롬프트, np === 네거티브 프롬프트
		if (checked) {  // unchecked
			if (type === 'p') setSelectedOptions(selectedOptions.filter((i) => i.eng !== item.eng));
			else setSelectedNOptions(selectedNOptions.filter((i) => i.eng !== item.eng))
		} else {  // checked
			if (type === 'p') setSelectedOptions([...selectedOptions, item]);
			else setSelectedNOptions([...selectedNOptions, item]);
		}
	}

	/** 프롬프트 전체 선택 */
	const handleClickAll = (type, items) => {
		if (type === 'p') {  // 프롬프트
			if (isSubset(items, selectedOptions)) {  // 전체선택 되어있을 경우, 선택 해제
				const filteredArray = selectedOptions.filter(a => !items.find(b => a.eng === b.eng));
				setSelectedOptions([...filteredArray]);
			} else {
				let arr = [...selectedOptions, ...items];
				const uniqueArr = [...new Set(arr)];  // 중복 제거
				setSelectedOptions([...uniqueArr]);
			}
		} else {  // 네거티브 프롬프트
			if (selectedNOptions?.length === negativeOptions.length) setSelectedNOptions([]);  // 전체선택 되어있을 경우, 선택 해제
			else setSelectedNOptions([...negativeOptions]);
		}
	}

	/** 부분집합 여부 확인 - 프롬프트 옵션 목록이 선택된 프롬프트 옵션에 포함되는지 확인 */
	const isSubset = (arrA, arrB) => {
		return arrA.every((itemA) => {
			return arrB.some((itemB) => {
				return itemA.eng === itemB.eng;
			});
		});
	};

	/* 이미지 상세 모달 열기 */
	const openDetail = (idx) => {
		lockScroll();
		setSelectedIdx(idx);
		setDetailVisible(true);
	};

	/* 이미지 상세 모달 닫기 */
	const closeDetail = () => {
		unlockScroll();
		setSelectedIdx(null);
		setDetailVisible(false);
	};

	/* 이미지 생성  */
	const [createImage, { loading }] = useMutation(CREATE_IMAGE, {
		onCompleted: async  (result) => {
			if (!result.createT2I) { alert('사용자가 많으므로 잠시 후 다시 시도해주세요.'); return false; }

			let images = result.createT2I.images;
			let info = JSON.parse(result.createT2I.info);

			let tmpImages = [];
			let arr = [...album];

			// 생성된 이미지 목록 indexedDB에 저장
			for (let idx = 0; idx < images.length; idx++) {
				let img = images[idx];
				let newBase64 = await convertBase64(img);  // base64 변환
				let data = {
					id: Date.now() + '_' + info.all_seeds[idx],
					base64: newBase64,
					userID: sessionStorage.getItem('potuid'),
					seed: info.all_seeds[idx],
					info: info,
					promptTxt: replaceText(promptTxt), // 프롬프트(직접입력)
					promptOptions: selectedOptions, // 프롬프트(옵션선택)
					nPromptTxt: replaceText(nPromptTxt) || '', // 네거티브 프롬프트(직접입력)
					promptNOptions: selectedNOptions, // 네거티브 프롬프트(옵션선택)
					image_style: selectedStyle, // 이미지 스타일
				};

				// indexedDB에 추가
				add(data).then((e) => error => { console.log(error); });
				arr.unshift(data);
				tmpImages.unshift(newBase64);
			}

			setImage(tmpImages);  // 결과창에 띄울 이미지 목록
			myGallery && setAlbum(arr);
			openImageModal(true); // 결과창 열기
		},
		onError: (error) => {
			console.log('[Error] => ', error);
			console.log('[Error Code] =>', error.networkError.statusCode);
			console.log('GraphQL API error:', error.message);

			if (error.networkError.statusCode === 401) {
				sessionStorage.clear();
				alert('세션이 만료되어 로그인이 필요합니다.');
				window.location.href = '/login';
			} else if (error.networkError.statusCode === 500) {
				alert('사용자가 많으므로 잠시 후 다시 시도해주세요.');
			} else {
				window.location.href = '/login';
				// alert('사용자가 많으므로 잠시 후 다시 시도해주세요.');
			}
		}
	});


	/** 이미지 생성 버튼 클릭 */
	const handleClickCreate = () => {
		isDBSupported().then(() => {  // DB 사용 가능 여부 체크

			if (!sessionStorage.getItem('potsid')) {
				alert('로그인 후 생성 가능합니다.');
				window.location.href = '/login';
				return false;
			}
			if (!promptTxt) { alert('이미지를 만들 키워드를 입력해주세요.'); return false; }

			// 이미지 생성 input
			let input = {
				...createOption,
				prompt_KOR: filter.clean(replaceText(promptTxt)), // 프롬프트(직접 입력)
				prompt_ENG: selectedOptions?.map(item => item.eng)?.toString(), // 프롬프트(옵션 선택)
				negative_prompt_KOR: replaceText(nPromptTxt), // 네거티브 프롬프트(직접 입력)
				negative_prompt_ENG: selectedNOptions.map(item => item.eng)?.toString() || "",// 네거티브 프롬프트(옵션 선택)
			}
			if(selectedStyle.key !== 'default') input["image_style"] = selectedStyle.key;

			createImage({
				variables: {
					input: input
				}
			})
		}).catch((error) => {  // indexedDB 사용 불가
			alert(error);
		});
	}

	/* 특수문자 제거 */
	const replaceText = (text) => {
		const regExp = /[,./]\s+/g;  // ,./와 바로 뒤 공백 제외
		// const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;  // 특수문자 제외
		const result = text?.replace(regExp, ', ');
		return result;
	}

	const [index, setIndex] = useState({ start: 0, end: 20 });  // 앨범 인덱스 (시작번호, 끝번호)
	const [totalCount, setTotalCount] = useState();  // 앨범 전체 이미지 개수

	/** 앨범 목록 세팅 */
	const setAlbumList = () => {
		getAll().then(albumFromDB => {  // indexedDB 데이터 가져오기
			setTotalCount(albumFromDB?.filter(al => al.userID === sessionStorage.getItem('potuid'))?.length);
			let userAlbum = albumFromDB.reverse().filter(al => al.userID === sessionStorage.getItem('potuid')).slice(index.start, index.end);
			setAlbum(userAlbum);
			setIsLoading(false);
			setIsStartLoading(false);
		}).catch(error => {
			console.log(error);
		})
	}

	/** 이미지 상세 모달 키워드 */
	const getKeyword = (text, options) => {
		let keyword = "";
		let optionKeyword = options?.map(op => op.kor)?.toString() || "";

		if (text && options?.length) {
			keyword = text + ',' + optionKeyword;
		} else {
			keyword = text + optionKeyword;
		}

		return keyword.replace(/(,\s*)/g, ', ');
	}

	/** 앨범에서 이미지 삭제 */
	const handleClickDelete = (id) => {
		deleteRecord(id).then(() => {
			alert('삭제 되었습니다.');
			closeDetail();
			setAlbumList();
		}).catch((error) => {
			console.error('Error deleting record', error);
		});
	}

	/** 이미지 다시 만들기(프롬프트, 옵션 세팅) */
	const handleClickRecreate = (imgInfo) => {
		setImage();  // 이미지 초기화
		setPromptTxt(imgInfo.promptTxt);  // 프롬프트
		setNPromptTxt(imgInfo.nPromptTxt);  // 네거티브프롬프트
		setSelectedOptions(imgInfo.promptOptions);  // 프롬프트(옵션선택)
		setSelectedNOptions(imgInfo.promptNOptions);  // 네거티브 프롬프트(옵션선택)
		setSelectedStyle(imgInfo.image_style);  // 이미지 스타일
		setCreateOption((createOption) => ({ ...createOption, n_iter: imgInfo.info.n_iter, cfg_scale: imgInfo.info.cfg_scale, steps: imgInfo.info.steps }))  // 생성옵션 세팅

		let ratio = ratioOptions.find(ro => ro.width === imgInfo?.info.width && ro.height === imgInfo?.info.height);
		handleSelectChange(ratio);  // 이미지 비율 선택 박스 세팅
	}

	/** 시드번호 복사 */
	const handleClickCopy = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			alert('복사 되었습니다.');
		}).catch((error) => {
			console.error('Error copying text: ', error);
		});
	}

	/** esc로 모달 닫기 */
	useEffect(() => {
		const escKeyModalClose = (e) => {
			if (e.keyCode === 27) {
				closeDetail();
			}
		};
		window.addEventListener("keydown", escKeyModalClose);
		return () => window.removeEventListener("keydown", escKeyModalClose);
	}, []);

	/** 앨범 탭 변경 */
	useEffect(() => {
		if (myGallery === true && page !== 0) {
			setAlbumList();
		} else if (!myGallery) {  // 추천이미지 목록
			setAlbum([...recommendedImage]);
		}
	}, [myGallery])

	/** 스크롤 페이징 <------------------------------------------------------------------------------------------ */
	const [page, setPage] = useState(0);  // 페이지 번호
	const [isLoading, setIsLoading] = useState(false);  // 데이터 로딩
	const [isStartLoading, setIsStartLoading] = useState(false);  // 데이터 로딩

	const itemsPerPage = 20;  // 한 페이지 당 보여줄 개수
	const startTarget = useRef(null);  // 스크롤 시작 타겟
	const endTarget = useRef(null);    // 스크롤 종료 타겟
	const scrollRef = useRef(null);    // 앨범 스크롤 영역 

	/** 옵저버 콜백 함수 */
	const callback = (num) => {
		if (num > 0) setIsLoading(true);  // 앨범 다음 페이지 가져오기
		else { setIsStartLoading(true); scrollRef.current.scrollTo({ top: 20, behavior: 'smooth' }); }  // 앨범 이전 페이지
		setPage((page) => page + num);
	}

	// IntersectionObserver 객체를 저장하는 useRef
	const startObserver = useRef(
		new IntersectionObserver(
			(entries, observer) => {
				const entry = entries[0];
				if (entry.isIntersecting) {
					callback(-1);
				}
			},
			{ rootMargin: '300px', threshold: 0.1 }
		)
	);

	const startObserve = (element) => { myGallery === true && startObserver.current.observe(element); };  // IntersectionObserver로 관찰 시작하는 함수
	const startUnobserve = (element) => { myGallery === true && startObserver.current.unobserve(element); };  // IntersectionObserver로 관찰 종료하는 함수

	// IntersectionObserver 객체를 저장하는 useRef
	const observer = useRef(
		new IntersectionObserver(
			(entries, observer) => {
				const entry = entries[0];
				if (entry.isIntersecting) { callback(1); }  // 교차 영역이 발생하면 콜백함수 호출
			},
			{ threshold: 0.1 }
		)
	);

	const observe = (element) => { myGallery === true && observer.current.observe(element); };
	const unobserve = (element) => { myGallery === true && observer.current.unobserve(element); };

	useEffect(() => {
		if (page === 1) { observe(endTarget.current); };  // IntersectionObserver로 스크롤이 endTarget 엘리먼트에 도달하면 콜백 함수를 호출

		if (0 === album?.length || totalCount <= page * itemsPerPage) {
			unobserve(endTarget.current);  // IntersectionObserver로 관찰 종료
			setIsLoading(false);
		} else if (page === 1) {
			startUnobserve(startTarget.current);
		}
	}, [album, myGallery]);

	useEffect(() => {
		if (page < 4) {
			setIndex({ start: 0, end: page * itemsPerPage });
		} else {  // 4페이지 이후부터는 일정 개수만 보이도록
			setIndex({ start: (page - 3) * itemsPerPage - page, end: page * itemsPerPage });
		}
	}, [page]);

	useEffect(() => {
		if (isLoading) {
			startUnobserve(startTarget.current);
			unobserve(endTarget.current);
		} else {
			page > 1 && startObserve(startTarget.current);
			(album.length >= 20 || page === 0) && observe(endTarget.current);
		}
	}, [isLoading, isStartLoading, myGallery]);

	useEffect(() => {
		if (page) setAlbumList();
	}, [index.start, index.end])
	// -------------------------------------------------------------------------------------------->

	return (
		<div className={`content ${myGallery !== 'hidden' ? 'narrow' : ''}`}>
			{loading && <LoadingSpinner />}
			{(isLoading || isStartLoading) && <div className="flex justify-content-center spinner"><Spinner style={{ width: '100px', height: '100px' }} /></div>}

			{myGallery == 'hidden' ?
				<>  {/** 메인 영역(생성 옵션) */}
					<Sidebar
						selectedStyle={selectedStyle}
						setSelectedStyle={setSelectedStyle}
						createOption={createOption}
						setCreateOption={setCreateOption}
						selectedRatio={selectedRatio}
						handleSelectChange={handleSelectChange}
					/>
					<div className="main">
						<div className="section">
							<div className="prompt-wrapper">
								{/* ▼ 프롬프트 ▼ */}
								<div className="prompt">
									<div className="textarea-wrapper">
										<textarea
											aria-label="prompt"
											placeholder="이미지를 만들 키워드를 입력하세요.
                                        	예) 아이스크림을 들고 있는 쿼카, 뉴욕, 아이스크림 가게"
											value={promptTxt}
											onChange={(e) => {
												let value = e.target.value;
												if (value.length > 1000) { alert('1000글자까지 입력 가능합니다.'); return false; }
												else setPromptTxt(value);
											}}
											onClick={() => { if (!sessionID) window.location.href = '/login' }}
										/>
										<div className="textarea-info"><span>{promptTxt?.length || 0} / <span id="text">1000</span></span><button type="button" onClick={() => { setPromptVisible('p'); lockScroll(); }}>추천키워드 추가</button></div>
									</div>
									{
										selectedOptions?.length !== 0 &&
										<>
											<div className="flex align-items-center justify-content-between">
												<div>추천 키워드<span>총 {selectedOptions?.length || ""}개</span></div>
												<button className="flex align-items-center" onClick={() => setSelectedOptions([])}><ResetIcon />재설정</button>
											</div>
											<div className="prompt-list">
												<ul className="flex">
													{
														selectedOptions?.map((option) => (
															<li key={'keyword_' + option.eng} className="prompt-item">{option.kor}<XIcon onClick={() => handleClickOption(true, option, 'p')} /></li>
														))
													}
												</ul>
											</div>
											<button className="flex align-items-center justify-content-center more" onClick={() => { setPromptVisible('p'); lockScroll(); }}>더보기<DownIcon /></button>
										</>
									}
								</div>
								{/* ▼ 네거티브 프롬프트 ▼ */}
								<div className="prompt">
									<div className="textarea-wrapper">
										<textarea
											aria-label="negative-prompt"
											placeholder="이미지에서 제외하고 싶은 키워드를 입력하세요.
                                       		예) 노이즈, 구름, 사람"
											value={nPromptTxt}
											onChange={(e) => {
												let value = e.target.value;
												if (value.length > 1000) { alert('1000글자까지 입력 가능합니다.'); return false; }
												else setNPromptTxt(value);
											}}
											onClick={() => { if (!sessionID) window.location.href = '/login' }}
										/>
										<div className="textarea-info"><span>{nPromptTxt?.length || 0} / <span id="text">1000</span></span><button type="button" onClick={() => { setPromptVisible('Np'); lockScroll(); }}>추천키워드 추가</button></div>
									</div>
									{
										selectedNOptions?.length !== 0 &&
										<>
											<div className="flex align-items-center justify-content-between">
												<div>추천 키워드<span>총 {selectedNOptions?.length || ""}개</span></div>
												<button className="flex align-items-center" onClick={() => setSelectedNOptions([])}><ResetIcon />재설정</button>
											</div>
											<div className="prompt-list">
												<ul className="flex">
													{
														selectedNOptions?.map((option) => (
															<li key={'keyword_' + option.eng} className="prompt-item">{option.kor}<XIcon onClick={() => handleClickOption(true, option, 'np')} /></li>
														))
													}
												</ul>
											</div>
											<button className="flex align-items-center justify-content-center more" onClick={() => { setPromptVisible('np'); lockScroll(); }}>더보기<DownIcon /></button>
										</>
									}
								</div>
							</div>
							<div className="btn-wrapper">
								<button className="btn btn-create" type="button" onClick={handleClickCreate}>생성하기</button>
							</div>
						</div>
					</div>
				</>
				:
				<div className={`gallery ${isMobile ? 'mobile' : ''}`}>
					<div className="gallery-area" ref={scrollRef}>
						{myGallery && <div ref={startTarget} id="start-target" style={{ height: '10px' }} />}
						{/* {isStartLoading && <div className="flex justify-content-center start-spinner"><Spinner style={{ width: '100px', height: '100px' }} /></div>} */}
						<div className="flex-section">
							<div className="section section-left">
								{
									album?.filter((_, idx) => idx % 2 === 0)?.map((image, index) => {  // 짝수번 이미지
										let imgSrc = myGallery ? image.base64 && `data:image/jpg;base64,${image.base64}` : image.imgSrc;
										return <img key={`${index}_${image.seed}`} src={imgSrc} onClick={() => { openDetail(index + index) }} />
									})
								}
							</div>
							<div className="section section-right">
								{
									album?.filter((_, idx) => idx % 2 === 1)?.map((image, index) => {  // 홀수번 이미지
										let imgSrc = myGallery ? image.base64 && `data:image/jpg;base64,${image.base64}` : image.imgSrc;
										return <img key={`${index}_${image.seed}`} src={imgSrc} onClick={() => { openDetail(index + index + 1) }} />
									})
								}
							</div>
						</div>
						{/* {isLoading && <div className="flex justify-content-center spinner"><Spinner style={{ width: '100px', height: '100px' }} /></div>} */}
						{myGallery && <div ref={endTarget} id="end-target" style={{ height: '10px' }} />}
					</div>
				</div>
			}


			{/* 이미지 상세 모달 --------------------------------- */}
			{album &&
				<div id="modal" className={`mobile-modal detail flex ${detailVisible ? "open" : ""}`}>
					<button type="button" className="btn-prev" onClick={closeDetail}><PrevIcon /></button>
					<div className="modal-body flex">
						<div className="image-wrapper">
							{selectedIdx !== null && <img id="created-image" className="modal-image" src={myGallery ? `data:image/jpg;base64,${album[selectedIdx]?.base64}` : album[selectedIdx]?.imgSrc} alt="modal" />}
						</div>
						<div className="btn-wrapper">
							{myGallery ?
								<button type="button" onClick={() => downloadImage(album[selectedIdx]?.base64)} title="이미지 다운로드"><DownloadIcon /></button>
								: <a href={album[selectedIdx]?.imgSrc} download><button type="button" title="이미지 다운로드"><DownloadIcon /></button></a>
							}
							{myGallery && <button type="button" onClick={() => handleClickDelete(album[selectedIdx]?.id)} title="이미지 삭제"><DeleteIcon /></button>}
							<button type="button" className="btn-recreate" onClick={() => { handleClickRecreate(album[selectedIdx]); closeDetail(); setMyGallery('hidden') }}><RefreshIcon />{"유사 이미지 생성"}</button>
						</div>
						<div className="content-wrapper flex">
							{/* <div className="flex align-items-center">
                            <p>시드번호</p>
                            <span>{album[selectedIdx]?.seed}</span>
                            <button type="button" className="btn-copy" onClick={() => handleClickCopy(album[selectedIdx]?.seed)}>복사</button>
                        </div> */}
							<div>
								<p>스타일</p>
								<div className="bg-gray">{album[selectedIdx]?.image_style?.name || '-'}</div>
							</div>
							<div>
								<p>키워드</p>
								<div className="bg-gray">
									{getKeyword(album[selectedIdx]?.promptTxt, album[selectedIdx]?.promptOptions)}
								</div>
							</div>
							<div>
								<p>제외 키워드</p>
								<div className="bg-gray">
									{getKeyword(album[selectedIdx]?.nPromptTxt, album[selectedIdx]?.promptNOptions) || "-"}
								</div>
							</div>
							<div>
								<p>옵션사항</p>
								<div className="bg-gray options">
									<div><span className="txt-black">이미지 비율</span>
										<span>{ratioOptions.find(ro => ro.width === album[selectedIdx]?.info.width && ro.height === album[selectedIdx]?.info.height)?.label}</span>
									</div>
									<div>
										<span className="txt-black">프롬프트 이행도</span>
										<span>{album[selectedIdx]?.info.cfg_scale}</span>
									</div>
									<div>
										<span className="txt-black">연산횟수</span>
										<span>{album[selectedIdx]?.info.steps}</span>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			}

			{/* 이미지 생성 결과 ------------------------------------------*/}
			<div id="modal" className={`mobile-modal image ${!loading && modalVisible ? "open" : ""}`}>
				<button className="btn-close" onClick={() => openImageModal(false)}><CloseIcon /></button>
				<div className="image-container">
					{image?.map((i, idx) =>
						<div key={"img_" + idx} className="image-wrapper">
							<img src={`data:image/jpg;base64,${i}`} />
						</div>
					)}
				</div>
			</div>

			{/* 프롬프트 선택 모달 ----------------------------------- */}
			<div id="modal" className={`prompt-modal mobile-modal ${promptVisible ? "open" : ""}`}>
				<button className="btn-close" onClick={() => { setPromptVisible(); unlockScroll(); }}><CloseIcon /></button>
				{
					promptVisible === 'p' ?  // 긍정프롬프트
						<div className="option-container">
							{options.map(option => (
								<div className="option-wrapper" key={option.category}>
									<p className="flex justify-content-between align-items-center">{option.category}
										<button className="btn-select" onClick={() => handleClickAll('p', option.list)}>
											{isSubset(option.list, selectedOptions) ? <CheckedIcon /> : <UncheckedIcon />}전체선택
										</button>
									</p>
									<ul>
										{option.list.map((item, idx) => {
											let checked = selectedOptions?.some(op => op.eng === item.eng);
											return (
												<li key={idx + item.eng} className={`prompt-item ${checked ? 'checked' : ''}`} onClick={() => handleClickOption(checked, item, 'p')}>
													{checked && <Check2Icon />}<span>{item.kor}</span>
												</li>
											)
										})}
									</ul>
								</div>
							))}
						</div>
						: // 부정프롬프트
						<div className="option-container">
							<div className="option-wrapper">
								<p className="flex justify-content-between">제외할 키워드
									<button className="btn-select" onClick={() => handleClickAll('np')}>
										{selectedNOptions?.length === negativeOptions.length ? <CheckedIcon /> : <UncheckedIcon />}전체선택
									</button>
								</p>
								<ul>
									{negativeOptions.map((item, idx) => {
										let checked = selectedNOptions?.some(op => op.eng === item.eng);
										return (
											<li key={idx + item.eng} className={`prompt-item  ${checked ? 'checked' : ''}`} onClick={() => handleClickOption(checked, item, 'np')}>
												{checked && <Check2Icon />}<span>{item.kor}</span>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
				}
				<div className="btn-wrapper">
					<button onClick={() => { promptVisible === 'p' ? setSelectedOptions([]) : setSelectedNOptions([]) }}>선택 초기화</button>
					<button className="btn-w-border" onClick={() => { setPromptVisible(); unlockScroll(); }}>적용</button>
				</div>
			</div>

			{/* 메뉴 ------------------------------------------ */}
			<div id="menu" className={`flex ${isMenuOpen ? "open" : ""}`}>
				<div className="menu-inner">
					<div className="btn-wrapper flex align-items-center justify-content-between">
						<NavLink onClick={() => setMyGallery('hidden')} className="flex align-items-center" id='nav-logo'><img src={Logo} alt="Logo" className='logo' /></NavLink>
						<CloseIcon className="btn-close" onClick={() => setIsMenuOpen(false)} />
					</div>
					<div className="menu-wrapper">
						<ul>
							<li className={myGallery === true ? 'selected' : ''} onClick={() => { setIsMenuOpen(false); setMyGallery(true) }}>내 앨범</li>
							<li className={!myGallery ? 'selected' : ''} onClick={() => { setIsMenuOpen(false); setMyGallery(false) }}>추천 둘러보기</li>
							<li><NavLink to="/privacy" onClick={() => setIsMenuOpen(false)}>개인정보처리방침</NavLink></li>
							<li><NavLink to="/service" onClick={() => setIsMenuOpen(false)}>이용약관</NavLink></li>
							{sessionID ? <li onClick={() => { logout(); window.location.href = '/login'; }}>로그아웃</li> : <li><NavLink to="/login">로그인</NavLink></li>}
						</ul>
					</div>
				</div>
				<div className="menu-bg" onClick={() => setIsMenuOpen(false)} />
			</div>
		</div>
	)
}


export default MobileMain;
