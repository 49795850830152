function LoadingSpinner() {

    return (
        <div className="loading-spinner">
            <div className="loadingio-spinner-rolling-gsqqlbrrgg">
                <div className="ldio-cdqaq8u03bi">
                    <div></div>
                </div>
            </div>
            <p>이미지를 생성 중입니다.</p>
        </div>
    )

}

export default LoadingSpinner;