import axios from 'axios';
import { AppContext } from "../App";
import { useContext, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import Logo from '../assets/svg/logo.svg'
import { ReactComponent as MenuIcon } from '../assets/svg/icon_menu.svg';
import { ReactComponent as PrevIcon } from '../assets/svg/icon_prev.svg';
import { useLogout } from '../hooks/useLogout';

function Nav() {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname } = location;
	const { sessionID, isMobile, isMenuOpen, setIsMenuOpen } = useContext(AppContext);
	
	const { logout } = useLogout();
	
	/** 메인으로 */
	const goToMain = () => {
		if (pathname === '/') window.location.href = '/main';
		else window.location.href = '/';
	}
	
	/** 메뉴 클릭 */
	const handleClickMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	}

	return (
		<nav>
			<NavLink onClick={goToMain} className="flex align-items-center" id='nav-logo'><img src={Logo} alt="Logo" className='logo' /></NavLink>
			{!isMobile ?
				<ul>
					{sessionID ? <li><a onClick={logout}>logout</a></li> : <li><NavLink to="/login">login</NavLink></li>}
				</ul>
				:
				(pathname === '/' || pathname === '/main') ? <MenuIcon stroke='#FFFFFF' onClick={handleClickMenu} />
					: (pathname === '/privacy' || pathname === '/service') ? <PrevIcon onClick={() => navigate(-1)} />
						: null
			}
		</nav>
	)
}

export default Nav;