import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
const component = <BrowserRouter><App /></BrowserRouter>;

root.render(component);
// ---------------------------------*****************************************
// if (rootElement.hasChildNodes()) {
//   const hydrateRoot = ReactDOM.hydrateRoot(rootElement, component);
//   hydrateRoot.render(component);
// } else {
//   root.render(component);
// }
// ---------------------------------*****************************************
