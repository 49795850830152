// 스타일 이미지
import styleRetro from './assets/images/style_retro.png';
import styleAnima from './assets/images/style_anime.png';
import styleArt3d from './assets/images/style_art3d.png';
import stylePixel from './assets/images/style_pixel.png';
import stylePerson from './assets/images/style_person.png';
import stylePerson2 from './assets/images/style_person2.png';
import styleDramatic from './assets/images/style_dramatic.png';
import defaultImg from './assets/images/style_default.jpg';


// 프롬프트 상세 옵션
export const options = [
  { 'category': '이미지 설정', 'list': [{ "eng": "rich colors", "kor": "풍부한 색상" }, { "eng": "painterly texture", "kor": "회화적 질감" }, { "eng": "trending on art communities", "kor": "예술 커뮤니티에서 유행" }, { "eng": "fantasy-inspired art", "kor": "판타지에 영감을 받은 예술" }, { "eng": "deep contrast", "kor": "깊은 대비" }, { "eng": "intricately detailed scene", "kor": "복잡하고 상세한 장면" }, { "eng": "symmetry", "kor": "대칭" }, { "eng": "masterpiece", "kor": "걸작" }, { "eng": "sharp focus", "kor": "날카로운 초점" }, { "eng": "best quality", "kor": "최고의 품질" }, { "eng": "high quality", "kor": "높은 품질" }, { "eng": "high Detail", "kor": "높은 디테일" }, { "eng": "studio quality", "kor": "스튜디오 품질" }, { "eng": "colorgrading", "kor": "색보정" }, { "eng": "trending on artstation", "kor": "아트스테이션에서 유행하는" }, { "eng": "golden ratio", "kor": "황금비율" }, { "eng": "award winning", "kor": "수상경력" }, { "eng": "oil painting", "kor": "오일 페인팅" }, { "eng": "post-processing", "kor": "후처리" }, { "eng": "tone mapping", "kor": "톤 매핑" }, { "eng": "4k", "kor": "4k" }, { "eng": "8k", "kor": "8k" }] },
  { 'category': '구도', 'list': [{ "eng": "wide-angle", "kor": "광각" }, { "eng": "aerial view", "kor": "조감도" }, { "eng": "aerial photography", "kor": "항공 사진" }, { "eng": "massive scale", "kor": "대규모" }, { "eng": "street level view", "kor": "스트리트 뷰" }, { "eng": "panoramic", "kor": "파노라마" }, { "eng": "overhead shot", "kor": "오버헤드 샷" }] },
  { 'category': '빛(조명)', 'list': [{ "eng": "bloom", "kor": "화사한" }, { "eng": "god rays", "kor": "신 광선" }, { "eng": "hard shadows", "kor": "단단한 그림자" }, { "eng": "studio lighting", "kor": "스튜디오 조명" }, { "eng": "soft lighting", "kor": "부드러운 조명" }, { "eng": "diffused lighting", "kor": "확산 조명" }, { "eng": "volumetric lighting", "kor": "체적 조명" }, { "eng": "specular lighting", "kor": "반사 조명" }, { "eng": "cinematic lighting", "kor": "영화 조명" }, { "eng": "luminescence", "kor": "발광" }, { "eng": "translucency", "kor": "반투명" }, { "eng": "global illumination", "kor": "전역 조명" }, { "eng": "indirect light", "kor": "간접광" }, { "eng": "ektachrome", "kor": "엑타크롬" }, { "eng": "glowing", "kor": "빛나는" }, { "eng": "shimmering light", "kor": "반짝이는 빛" }, { "eng": "halo", "kor": "후광" }, { "eng": "iridescent", "kor": "무지개 빛깔의" }, { "eng": "backlighting", "kor": "백라이트" }] },
  { 'category': '색상', 'list': [{ "eng": "vibrant", "kor": "강렬한" }, { "eng": "muted colors", "kor": "차분한 색상" }, { "eng": "vivid color", "kor": "선명한 색상" }, { "eng": "lush", "kor": "풍부한" }, { "eng": "low contrast", "kor": "낮은 콘트라스트" }, { "eng": "vintage", "kor": "빈티지" }, { "eng": "aesthetic", "kor": "미적인" }, { "eng": "psychedelic", "kor": "사이키델릭" }, { "eng": "monochrome", "kor": "단색화" }] },
  { 'category': '카메라/렌즈', 'list': [{ "eng": "shoot on sony mirrorless 35mm f/2.8", "kor": "소니" }, { "eng": "shoot on Fujifilm XT3", "kor": "후지필름" }, { "eng": "shoot on Canon Eos 5D", "kor": "캐논" }, { "eng": "shoot on 80mm Sigma f/1.4", "kor": "시그마" }] },
  // { 'category': '대상', 'list': [{ "eng": "man", "kor": "남자" }, { "eng": "woman", "kor": "여자" }, { "eng": "celebrity", "kor": "연예인" }, { "eng": "landscapes", "kor": "풍경" }, { "eng": "cars", "kor": "자동차" }, { "eng": "architecture", "kor": "건축물" }, { "eng": "animals", "kor": "동물" }, { "eng": "food", "kor": "음식" }] },
  // { 'category': '스타일', 'list': [{ "eng": "anime(cartoon)", "kor": "애니메이션(만화)" }, { "eng": "character", "kor": "캐릭터" }, { "eng": "illustration", "kor": "일러스트" }, { "eng": "photography", "kor": "사진" }, { "eng": "3d", "kor": "3D" }] },
  // { 'category': '분위기', 'list': [{ "eng": "scifi", "kor": "공상과학" }, { "eng": "retro", "kor": "레트로" }, { "eng": "realistic", "kor": "사실적인" }, { "eng": "artistic", "kor": "예술적인" }] },
];


// 네거티브 프롬프트 상세 옵션
export const negativeOptions = [
  { eng: "missing arms", kor: "팔 제외" },
  { eng: "missing legs", kor: "다리 제외" },
  { eng: "extra arms", kor: "팔 많음" },
  { eng: "extra legs", kor: "다리 많음" },
  { eng: "fused fingers", kor: "융합된 손가락" },
  { eng: "too many fingers", kor: "많은 손가락" },
  { eng: "long neck", kor: "긴 목" },
  // { eng: "dehydrated", kor: "탈수" },
  { eng: "out of frame", kor: "프레임 밖으로 벗어남" },
  { eng: "bad anatomy", kor: "해부학적으로 나쁜 모습" },
  { eng: "bad proportions", kor: "나쁜 비율" },
  { eng: "extra limbs", kor: "손발이 여러 개" },
  { eng: "worst quality", kor: "나쁜 퀄리티" },
  { eng: "low quality", kor: "낮은 퀄리티" },
  { eng: "disconnected limbs", kor: "분리된 손발" },
  { eng: "mutation", kor: "돌연변이" },
  { eng: "amputation", kor: "절단" },
  { eng: "disfigured", kor: "손상" },
  { eng: "bad art", kor: "나쁜 예술" },
  { eng: "deformed", kor: "변형된" },
  { eng: "morbid", kor: "병적" },
  // { eng: "mutilated", kor: "절단된" },
  { eng: "poorly drawn hands", kor: "잘못 그린 손" },
  { eng: "poorly drawn feet", kor: "잘못 그려진 발" },
  { eng: "poorly drawn face", kor: "잘못 그려진 얼굴" },
];


// 이미지 비율 옵션
export const ratioOptions = [
  { value: '1:1', label: '1:1 (512 * 512)', width: 512, height: 512 },
  { value: '4:3', label: '4:3 (720 * 540)', width: 720, height: 540 },
  { value: '3:4', label: '3:4 (540 * 720)', width: 540, height: 720 },
  { value: '16:9', label: '16:9 (1280 * 720)', width: 1280, height: 720 },
  { value: '9:16', label: '9:16 (720 * 1280)', width: 720, height: 1280 },
];


// 스타일 옵션
export const styleOptions = [
  { key: 'default', style: 'default', name: '기본', src: defaultImg, disabled: false },
  // { key: 'person2', style: 'person', name: '인물(동양)', src: stylePerson2 },
  { key: 'person', style: 'person', name: '인물', src: stylePerson, disabled: false },
  { key: 'anima', style: 'anima', name: '애니메이션', src: styleAnima, disabled: false },
  { key: 'dramatic', style: 'dramatic', name: '사진', src: styleDramatic, disabled: false },
  // 0612 네이버 등록을 위해 임시로 삭제 { key: 'retro', style: 'retro', name: '레트로 애니메이션', src: styleRetro, disabled: true },
  // 0612 네이버 등록을 위해 임시로 삭제 { key: 'art3d', style: 'art3d', name: '3D아트', src: styleArt3d, disabled: true },
  // 0612 네이버 등록을 위해 임시로 삭제 { key: 'pixel', style: 'pixel', name: '픽셀아트', src: stylePixel, disabled: true },
]