import RangeSlider from 'react-range-slider-input';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";

import CustomSelectBox from "../components/CustomSelect";
import { ReactComponent as QuestionIcon } from '../assets/svg/icon_question.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check.svg';
import { ratioOptions, styleOptions } from '../options';

function Sidebar(props) {

  const { selectedStyle, setSelectedStyle, createOption, setCreateOption, handleSelectChange, selectedRatio } = props;

  /** 이미지 스타일 선택 */
  const handleClickStyle = (option) => {
    if (option.style === 'default') {  // 기본 스타일인 경우, 옵션에서 image_style 빼기
      const tmpOption = { ...createOption };
      delete tmpOption.image_style;
      setCreateOption({ ...tmpOption })
    } else {
      setCreateOption({ ...createOption, image_style: option.style })
    }
    setSelectedStyle(option);
  }

  return (
    <div className="sidebar">
      <div>
        <div className="button-wrapper">
          <button className="active" >Text to Image</button>
          {/* <button onClick={() => alert('준비중입니다.')}>Image to Image</button> */}
        </div>
        <div className="option-wrapper">
          <ul className="option-list">
            <li className="option-item">
              <p className="option-title flex align-items-center gap-5">스타일<QuestionIcon id="style-tooltip" /></p>
              <div className="option-content images">
                {
                  styleOptions.map((option, idx) => {
                    let selected = selectedStyle?.key === option.key;
                    let disabled = option.disabled;  // 사용하지 않는 옵션 => true
                    return (
                      disabled ?
                        <div key={option.key} className="img-wrapper disabled" style={{ background: `url(${option.src}) center no-repeat` }} onClick={() => alert('준비중입니다.')}>{option.name}</div>
                        : selected ?
                          <div key={option.key} className="img-wrapper flex gap-10 selected"><img src={option.src} alt='icon' />{option.name}<CheckIcon /></div>
                          : <div key={option.key} className="img-wrapper" style={{ background: `url(${option.src}) center no-repeat` }} onClick={() => handleClickStyle(option)}>{option.name}</div>
                    )
                  })
                }
              </div>
              <ReactTooltip anchorId="style-tooltip" place="right" variant="dark" className="tooltip" multiline={true}
                content="생성되는 이미지의 스타일을 지정하는 항목이며 선택한 스타일에 따라 이미지 풍이 변화 됩니다." />
            </li>
            <li className="option-item">
              <p className="option-title flex align-items-center gap-5">이미지 설정<QuestionIcon id="ratio-tooltip" /></p>
              <p className="option-sub-title">비율</p>
              <CustomSelectBox options={ratioOptions} onChange={handleSelectChange} selectedValue={selectedRatio} />
              <ReactTooltip anchorId="ratio-tooltip" place="right" variant="dark" className="tooltip" multiline={true}
                content="생성되는 이미지의 사이즈를 선택하는 항목입니다." />
              {createOption?.width === 512 &&  // 이미지 비율 1:1(width 512px)인 경우에만 이미지 수 선택 가능
                <div style={{ marginTop: '26px' }}>
                  <div className="option-title flex justify-content-between">
                    <p className="flex align-items-center gap-5">이미지 수<QuestionIcon id="count-tooltip" /></p>
                    <span>{createOption.n_iter}</span>
                  </div>
                  <RangeSlider
                    className="single-thumb purple"
                    min="1" max="4" step="1"
                    value={[1, createOption.n_iter]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    onInput={(range) => setCreateOption({ ...createOption, n_iter: range[1] })}
                  />
                  <div className="flex justify-content-between range-text" style={{ padding: '18px 5px 0' }}>
                    {[1, 2, 3, 4].map(item => <span key={`${item}`} onClick={(range) => setCreateOption({ ...createOption, n_iter: item })}>{item}</span>)}
                  </div>
                  <ReactTooltip anchorId="count-tooltip" place="right" variant="dark" className="tooltip" multiline={true}
                    content="생성되는 이미지 수량을 선택하는 항목이며 생성하는 수량이 많을 수록 생성속도가 느려집니다." />
                </div>
              }
            </li>
            <li className="option-item ranges">
              <div className="option-title flex justify-content-between">
                <p className="flex align-items-center gap-5">프롬프트 이행도<QuestionIcon id="scale-tooltip" /></p>
                <span>{createOption.cfg_scale}</span>
              </div>
              <RangeSlider
                className="single-thumb blue"
                min="1" max="20" step="1"
                value={[1, createOption.cfg_scale]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                onInput={(range) => setCreateOption({ ...createOption, cfg_scale: range[1] })}
              />
              <ReactTooltip anchorId="scale-tooltip" place="right" variant="dark" className="tooltip" multiline={true}
                content="프롬프트 이행도 값이 높을 수록 입력한 키워드에 근접한 이미지를 생성합니다." />
              <div className="option-title flex justify-content-between">
                <p className="flex align-items-center gap-5">연산횟수<QuestionIcon id="step-tooltip" /></p>
                <span>{createOption.steps}</span>
              </div>
              <RangeSlider
                className="single-thumb blue"
                min="1" max="30" step="1"
                value={[1, createOption.steps]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                onInput={(range) => setCreateOption({ ...createOption, steps: range[1] })}
              />
              <ReactTooltip anchorId="step-tooltip" place="right" variant="dark" className="tooltip" multiline={true}
                content="연산횟수 값이 높을 수록 생성되는 이미지의 퀼리티 및 입력한 키워드에 근접하지만 생성속도를 느리게 할 수 있습니다." />
            </li>
          </ul>
        </div>
      </div>
      <div className="policy flex">
        <div className="companyinfo">
        (주)포스팟 <br/>
        대표이사: 김교순<br/>
        사업자번호 : 545-86-01108<br/>
        contact@pospot.kr<br/><br/>
        </div>
        <NavLink to="/privacy">개인정보처리방침</NavLink>
        <NavLink to="/service">이용약관</NavLink>
      </div>
    </div>
  )
}

export default Sidebar;