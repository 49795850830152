import sample01 from './assets/images/sample_01.jpg';
import sample02 from './assets/images/sample_02.jpg';
import sample03 from './assets/images/sample_03.jpg';
import sample04 from './assets/images/sample_04.jpg';
import sample05 from './assets/images/sample_05.jpg';
import sample06 from './assets/images/sample_06.jpg';
import sample07 from './assets/images/sample_07.jpg';
import sample08 from './assets/images/sample_08.jpg';
import sample09 from './assets/images/sample_09.jpg';

export const recommendedImage = [
    {
        imgSrc: sample01,
        promptTxt: `a girl in the ocean, swimming , start, planet, shinny, 8k, realistic, high resolution, Properly proportioned facial features, fairy tales, floating , fighting with alien, whole body, wide angle , pure water, holding her breath, some tropical fishs, seaweeds`,
        nPromptTxt: `((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)), ((b&w)), wierd colors, blurry, (((duplicate))), ((morbid)), ((mutilated)), [out of frame], extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), ((ugly)), blurry, ((bad anatomy)), (((bad proportions))), ((extra limbs)), cloned face, (((disfigured))), out of frame, ugly, extra limbs, (bad anatomy), gross proportions, (malformed limbs), ((missing arms)), ((missing legs)), (((extra arms))), (((extra legs))), mutated hands, (fused fingers), (too many fingers), (((long neck))),`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'default', style: 'default', name: '기본' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample02,
        promptTxt: `beautiful mature Swedish college girl, in track outfit, outside on track field, ((slim, petite)), photorealistic, detailed,hyper detailed, realistic skin texture, armature, best quality, ultra high res, (photorealistic:1.4), high resolution, detailed, look like Nicole Kidman,looks like a real person,mix4,(8k, RAW photo, best quality, masterpiece:1.2), (realistic, photo-realistic:1.37),perfect_eyes,same eyes, cute, professional lighting, photon mapping, radiosity, physically-based rendering`,
        nPromptTxt: `text, ugly eyes, deformed, mutated, bad anatomy, out of view, cut off,ugly face, paintings, sketches, (worst quality:2), (low quality:2),(normal quality:2), lowres, normal quality, ((monochrome)), ((grayscale)),skin spots, acnes, skin blemishes, age spot, glans,nude, naked`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'person', style: 'person', name: '인물' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample03,
        promptTxt: `돼지고기 바베큐 , 풀드포크, 접시에 놓인 텍사스 바베큐, 맥도널드 햄버거, 펩시 콜라`,
        nPromptTxt: ``,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'default', style: 'default', name: '기본' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample05,
        promptTxt: `The whole body of a strange-looking monster soldier, 후지필름, 높은 디테일`,
        nPromptTxt: `nsfw , blurry , low quality lowres, low quality lowres:low polygon 3D game, monochrome sketch rough graffiti, very ugly fat obesity scar, chibi, poorly drawn bad anatomy, graffiti unbecoming colorfully, incoherent background, long body, duplicate comparison, pedophile, sketch retro_artstyle doujinshi, sketch, text font ui error missing digit blurry, JPEG artifacts signature hazy bleary, monochrome parody meme, historical picture, disfigured mutated malformed twisted human body, futanari tranny, tentacle skeleton, vomit suicide death dirty, 잘못 그린 손, 잘못 그려진 발, 잘못 그려진 얼굴, 나쁜 예술, 손상, 절단, 돌연변이, 분리된 손발, 낮은 퀄리티, 해부학적으로 나쁜 모습, 나쁜 비율`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'person', style: 'person', name: '인물' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample06,
        promptTxt: `20 year old k-pop girl , toy-poodle, cuty girl, chihuahua, beautiful girl, maltese, sexy body, pomeranian, masterpiece, teacup dog, light smile, house, dynamic pose, hug to real dog, masterpiece, a very hansome 20-year-old korea man, beautiful detailed hands, {lustrous skin}, beautiful-detailed eyes, korean man:1.8 {{bishounen}}, ultra detailed, smiling, high quality, lustrous skin, brown long hair, 1.6 mishoujo, bichonfrise, real dog, Yorkshire Terrier, Retriever, 후지필름, 높은 디테일`,
        nPromptTxt: `nsfw , blurry , low quality lowres, low quality lowres:low polygon 3D game, monochrome sketch rough graffiti, very ugly fat obesity scar, chibi, poorly drawn bad anatomy, graffiti unbecoming colorfully, incoherent background, long body, duplicate comparison, pedophile, sketch retro_artstyle doujinshi, sketch, text font ui error missing digit blurry, JPEG artifacts signature hazy bleary, monochrome parody meme, historical picture, disfigured mutated malformed twisted human body, futanari tranny, tentacle skeleton, vomit suicide death dirty, 잘못 그린 손, 잘못 그려진 발, 잘못 그려진 얼굴, 나쁜 예술, 손상, 절단, 돌연변이, 분리된 손발, 낮은 퀄리티, 해부학적으로 나쁜 모습, 나쁜 비율`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'person', style: 'person', name: '인물' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample07,
        promptTxt: `걷고있는 남자`,
        nPromptTxt: `나쁜 예술, 변형된, 병적, 잘못 그린 손, 돌연변이, 분리된 손발, 낮은 퀄리티, 나쁜 퀄리티, 손발이 여러 개`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'anima', style: 'anima', name: '애니메이션' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample08,
        promptTxt: `귀여운 남자, 후지필름, 최고의 품질, 높은 품질, 높은 디테일`,
        nPromptTxt: `이상한 얼굴, 잘못된 얼굴, 못생긴 남자, (worst quality:2), (low quality:2), (normal quality:2), lowres, normal quality, bad-artist , ((monochrome)), ((grayscale)), skin spots, acnes, skin blemishes, age spot, glans, extra fingers, fewer fingers, strange fingers, bad hand, badhandv4, tatoo, 나쁜 예술, 변형된, 병적, 잘못 그린 손, 돌연변이, 분리된 손발, 낮은 퀄리티, 나쁜 퀄리티, 손발이 여러 개`,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'person', style: 'person', name: '인물' },
        promptOptions: [],
        promptNOptions: []
    },
    {
        imgSrc: sample09,
        promptTxt: `(masterpiece, top quality, best quality, official art, beautiful and aesthetic:1.2), (1girl:1.3), cute korean girl, extremely detailed,(fractal art:1.1),(colorful:1.1)(flowers:1.3),highest detailed,(zentangle:1.2), (dynamic pose), (abstract background:1.3), (shiny skin), (many colors:1.4), ,(earrings), (feathers:1.5), full nude, pink tits, pussy, pussy with hair, small tattoo on boob, black strip suede, 배꼽, shoes`,
        nPromptTxt: ``,
        info: {
            width: 512,
            height: 512,
            cfg_scale: 7,
            steps: 20
        },
        image_style: { key: 'person', style: 'person', name: '인물' },
        promptOptions: [],
        promptNOptions: []
    },
]