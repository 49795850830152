import { gql } from "@apollo/client";

export const CREATE_IMAGE = gql`
mutation insertT2I($input: T2IInput!) {
    createT2I(T2IInput: $input) {
      images
      info
    }
  }
`;

