import { useContext, useEffect, useState } from "react";
import KakaoLogin from "react-kakao-login";
// import NaverLogin from "react-naver-login";
import { useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Logo from '../assets/svg/logo.svg'
import { encryptData } from "../utils/encrypt";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import { ReactComponent as NaverIcon } from '../assets/svg/naver_logo.svg'
import { ReactComponent as KakaoIcon } from '../assets/svg/kakao_logo.svg'
import { ReactComponent as GoogleIcon } from '../assets/svg/google_logo.svg'
import { useRef } from "react";

function Login() {

	const { naver } = window;
	const { setSessionID, isMobile, isTablet } = useContext(AppContext);

	const naverRef = useRef();  // 네이버 로그인 버튼 ref
	const navigate = useNavigate();


	/** 소셜 로그인 성공 후 로그인 API 호출 */
	const onLoginSuccess = (type, email) => {
		const axiosInstance = axios.create({
			baseURL: process.env.REACT_APP_API_URL,
			headers: {
				"Content-Type": `application/json`,
				"x-api-key": process.env.REACT_APP_API_KEY
			}
		});

		const formData = {
			"userEmail": email,
			"password": encryptData(email),
			"channel": type
		}

		// 로그인 API 호출
		axiosInstance.post('/login', formData).then(res => { 
			let resData = res.data;
			if (resData.code === 200) {
				sessionStorage.setItem('potsidtype', type);  // 로그아웃 시 필요
				sessionStorage.setItem('potsid', resData.sessionID);  // 세션아이디
				sessionStorage.setItem('potemail', resData.email);    // 이메일
				sessionStorage.setItem('potuid', resData.userID);        // userID
				setSessionID(resData.sessionID);
				navigate('/main');
			}
		}).catch(error => {
			console.log(error);
		})
	}


	/** 구글 로그인 */
	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const userInfo = await axios.get(  // 로그인 성공 후 받은 토큰으로 사용자 정보 호출
				'https://www.googleapis.com/oauth2/v3/userinfo',
				{ headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
			);

			onLoginSuccess('G', userInfo.data.email);
		},
		onError: errorResponse => console.log(errorResponse),
	});


	/** 네이버 로그인 */
	const initializeNaverLogin = () => {
		const naverLogin = new naver.LoginWithNaverId({
			clientId: process.env.REACT_APP_NAVER_ID,
			callbackUrl: process.env.REACT_APP_FRONT_URL + '/login/',
			isPopup: false,
			loginButton: { color: 'green', type: 3, height: 58 },
			callbackHandle: true,
		})

		naverLogin.init();  // 네이버 로그인 초기화
		naverLogin.logout();

		naverLogin.getLoginStatus(async function (status) {
			if (status) {
				const email = naverLogin.user.getEmail();
				onLoginSuccess('N', email);
			} else {
				// console.log('naver login error');
			}
		})
	}

	useEffect(() => {
		initializeNaverLogin();
	}, [])

	return (
		<div className="login-container">
			<div className="login-box">
				<img src={Logo} alt="Logo" className='logo' />
				<div className="btn-wrapper">
					{/* 네이버 로그인 (기본 버튼 숨김) --------------------------- */}
					<div ref={naverRef} id="naverIdLogin" style={{ display: 'none' }} />
					<div className="btn-login" onClick={() => naverRef.current.children[0].click()}><NaverIcon />네이버로 시작하기</div>

					{/* 카카오 로그인 --------------------------- */}
					<KakaoLogin
						token={process.env.REACT_APP_KAKAO_KEY}
						onSuccess={(res) => {
							let email = res.profile.kakao_account.email;
							onLoginSuccess('K', email);
						}}
						onFail={(err) => console.log("로그인실패", err)}
						onLogout={() => console.log("로그아웃")}
						render={({ onClick }) => (
							<div className="btn-login" onClick={() => { onClick(); }}><KakaoIcon />카카오로 시작하기</div>
						)}
					/>

					{/* 구글 로그인 --------------------------- */}
					<div className="btn-login" onClick={googleLogin}><GoogleIcon /> 구글로 시작하기</div>
				</div>
			</div>
		</div>
	)
}

export default Login;

