import '../assets/css/style.css';

function Privacy() {

    return (
        <div className="text-content">
            <h2>
                <strong>개인정보처리방침</strong>
            </h2>
            <h2>&nbsp;</h2>
            <p>
                <strong>제1조(개인정보의 처리 목적)</strong>
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                <strong>
                    &lt; AISPOT &gt;('www.pospot.kr'이하 'AISPOT')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. <br />
                    처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 <br />
                    이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                </strong>
            </p>
            <ol>
                <li>홈페이지 회원가입 및 관리</li>
            </ol>
            <p>
                회원 가입의사 확인, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만
                아동의 개인정보 처리 시 법정대리인의 동의여부 확인 목적으로 개인정보를
                처리합니다.
            </p>
            <ol start={2}>
                <li>재화 또는 서비스 제공</li>
            </ol>
            <p>콘텐츠 제공을 목적으로 개인정보를 처리합니다.</p>
            <h2>&nbsp;</h2>
            <p>
                <strong>제2조(개인정보의 처리 및 보유 기간)</strong>
                <br />
                <br />
                ①&nbsp;<em>&lt; </em>AISPOT<em> &gt;</em>은(는) 법령에 따른 개인정보
                보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
                보유·이용기간 내에서 개인정보를 처리·보유합니다.
                <br />
                <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            </p>
            <ul>
                <li>
                    &lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는 수집.이용에 관한
                    동의일로부터&lt;회원탈퇴 시 까지&gt;까지 위 이용목적을 위하여
                    보유.이용됩니다.
                </li>
                <li>보유근거 : 생성한 이미지에 대한 소유권</li>
            </ul>
            <h2>&nbsp;</h2>
            <p>
                <strong>제3조(처리하는 개인정보의 항목)</strong>
                <br />
                <br />
                ①&nbsp;<em>&lt; </em>AISPOT<em> &gt;</em>은 다음의 개인정보 항목을 처리하고
                있습니다.
            </p>
            <ul>
                <li>&lt; 홈페이지 회원가입 및 관리 &gt;</li>
                <li>필수항목 : 이메일</li>
                <li>선택항목 : 로그인ID, 이메일</li>
            </ul>
            <h2>&nbsp;</h2>
            <p>
                <strong>제4조(만 14세 미만 아동의 개인정보 처리에 관한 사항)</strong>
            </p>
            <p>
                <br />① &lt;개인정보처리자명&gt;은 만 14세 미만 아동에 대해 개인정보를
                수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의
                개인정보를 수집합니다.
            </p>
            <ul>
                <li>필수항목 : 법정 대리인의 성명, 관계, 연락처</li>
            </ul>
            <p>
                ② 또한, &lt;개인정보처리자명&gt;의 &lt;처리목적&gt; 관련 홍보를 위해 아동의
                개인정보를 수집할 경우에는 법정대리인으로부터 별도의 동의를 얻습니다.
            </p>
            <h2>&nbsp;</h2>
            <p>
                <strong>제5조(개인정보의 파기절차 및 파기방법)</strong>
            </p>
            <p>
                <em>
                    <br />
                </em>
                <em>① </em>&lt; AISPOT &gt; 은 개인정보 보유기간의 경과, 처리목적 달성 등
                개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                <br />
                <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
                달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
                경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                달리하여 보존합니다.
                <br />
                <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <br />
                1. 파기절차
                <br />
                &lt; AISPOT &gt; 은 파기 사유가 발생한 개인정보를 선정하고, &lt; AISPOT &gt;
                의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </p>
            <ol start={2}>
                <li>파기방법</li>
            </ol>
            <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다</p>
            <h2>
                <br />
                <br />
            </h2>
            <p>
                <strong>
                    제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
                </strong>
            </p>
            <p>
                <em>
                    <br />
                </em>
                ① 정보주체는 AISPOT에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
                등의 권리를 행사할 수 있습니다.
            </p>
            <p>
                ② 제1항에 따른 권리 행사는AISPOT에 대해 「개인정보 보호법」 시행령
                제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며
                AISPOT은(는) 이에 대해 지체 없이 조치하겠습니다.
            </p>
            <p>
                ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
                대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한
                고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </p>
            <p>
                ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조
                제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
            </p>
            <p>
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로
                명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            </p>
            <p>
                ⑥ AISPOT은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
                처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
                확인합니다<em>.</em>
            </p>
            <h2>&nbsp;</h2>
            <p>
                <strong>제7조(개인정보의 안전성 확보조치에 관한 사항)</strong>
                <strong>
                    <br />
                </strong>
                <strong>
                    <br />
                </strong>
                <strong>
                    &lt; AISPOT &gt;은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를
                    취하고 있습니다.
                </strong>
            </p>
            <ol>
                <li>
                    내부관리계획의 수립 및 시행
                    <br />
                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                    <br />
                    <br />
                    2. 개인정보에 대한 접근 제한
                    <br />
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 <br />
                    개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

                    <br />
                    3. 개인정보의 암호화
                    <br />
                    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, <br />
                    본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                    <br />
                    <br />
                    4. 비인가자에 대한 출입 통제
                    <br />
                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                    <br />
                    <br />
                    5. 문서보안을 위한 잠금장치 사용
                    <br />
                    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                </li>
            </ol>
            <h2>&nbsp;</h2>
            <p>
                <strong>
                    제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한
                    사항)
                </strong>
            </p>
            <p>
                <br />
                AISPOT 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는
                ‘쿠키(cookie)’를 사용하지 않습니다.
            </p>
            <p>
                <strong>제9조 (개인정보 보호책임자에 관한 사항)</strong>
            </p>
            <p>
                ①&nbsp;AISPOT&nbsp;은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <ul>
                <li>▶ 개인정보 보호책임자</li>
                <li>성명 : 윤준성</li>
                <li>소속 : 경영기획본부</li>
                <li>직급 : 과장</li>
                <li>연락처 : 031-5175-1108&nbsp;</li>
                <li>이메일: pospot@gmail.com</li>
            </ul>
            <p>※ 개인정보 보호 담당부서로 연결됩니다.</p>
            <ul>
                <li>▶ 개인정보 보호 담당부서</li>
                <li>부서명 : 경영기획본부</li>
                <li>담당자 : 윤준성</li>
                <li>연락처 : 031-5175-1108</li>
            </ul>
            <p>
                ② 정보주체는 AISPOT의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보
                보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
                담당부서로 문의하실 수 있습니다. AISPOT은 정보주체의 문의에 대해 지체 없이
                답변 및 처리해드릴 것입니다.
            </p>
            <h2>&nbsp;</h2>
            <p>
                <strong>제11조(개인정보의 열람청구를 접수·처리하는 부서)</strong>
                <strong>
                    <br />
                </strong>
                <strong>정보주체는 </strong>
                <strong>｢</strong>
                <strong>개인정보 보호법</strong>
                <strong>｣</strong>
                <strong>
                    {" "}
                    제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
                </strong>
            </p>
            <h2>&nbsp;</h2>
            <p>
                <strong>
                    <em>제12조(정보주체의 권익침해에 대한 구제방법)</em>
                </strong>
            </p>
            <p>
                <em>
                    <br />
                </em>
                정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
                한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수
                있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에
                문의하시기 바랍니다.
                <br />
                <br />
                1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
                <br />
                2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                <br />
                3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                <br />
                4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                <br />
                <br />
                「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제),
                제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이
                행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
                행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
                <br />
                <br />※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
                홈페이지를 참고하시기 바랍니다.
            </p>
        </div>
    )
}

export default Privacy;