import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import Nav from './components/Nav';
import Main from './views/main';
import MobileMain from './views/MobileMain';
import Login from './views/Login';
import Service from './views/Service';
import Privacy from './views/Privacy';
import { DBConfig } from './indexedDB/DBConfig';
import { initDB } from 'react-indexed-db';
import { useMediaQuery } from 'react-responsive';
import '../src/assets/css/reset.css';
import '../src/assets/css/common.css';
import '../src/assets/css/style.css';
import '../src/assets/css/mobileStyle.css';

export const AppContext = createContext();

initDB(DBConfig);  // 인덱스디비 초기화

function App() {

  const [sessionID, setSessionID] = useState();  // 세션아이디
  const [isMenuOpen, setIsMenuOpen] = useState(false);  // 모바일 햄버거 메뉴 오픈 여부

  /** 아폴로 클라이언트 세팅 */
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL, // GraphQL API 엔드포인트 URL
    cache: new InMemoryCache(),
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "sessionID": sessionStorage.getItem('potsid')
    }
  });

  const isMobile = useMediaQuery({ maxWidth: 820 });  // 모바일 (<= iPad Air)
  // const isTablet = useMediaQuery({ minWidth: 821, maxWidth: 1180 });  // 태블릿 

  useEffect(() => {
    let potsid = sessionStorage.getItem('potsid');
    if (potsid) setSessionID(potsid);
  }, []);


  /** 모바일 주소창 높이 대응 <------- */
  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  /** ------------------------------> */

  return (
    <div className={`App container ${isMobile ? 'mobile' : ''} ${isMenuOpen ? 'hidden' : ''}`}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
        <AppContext.Provider value={{ sessionID, setSessionID, isMobile, isMenuOpen, setIsMenuOpen }}>
          <ApolloProvider client={client}>
            <Nav />
            <Routes>
              {isMobile ? <Route path="/" element={<MobileMain />} /> : <Route path="/" element={<Main />} /> }
              {isMobile ? <Route path="/main" element={<MobileMain />} /> : <Route path="/main" element={<Main />} />}
              <Route path="/login" element={<Login />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/service" element={<Service />} />
            </Routes>
            {/* <Footer /> */}
          </ApolloProvider>
        </AppContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}



export default App;
