import axios from "axios";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../App";
import { googleLogout } from "@react-oauth/google";

export function useLogout() {
    const location = useLocation();
    const { pathname } = location;
    const { setSessionID, isMenuOpen, setIsMenuOpen } = useContext(AppContext);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
            "sessionID": sessionStorage.getItem('potsid')
        },
    })

    /** 로그아웃 버튼 클릭 */
    const logout = () => {
        // 로그아웃 API 호출
        axiosInstance.post('/logout').then(res => {
            const type = sessionStorage.getItem('potsidtype');
            clearSession();

            if (type === 'G') googleLogout();  // 구글 로그아웃
            else if (type === 'K') kakaoLogout();  // 카카오 로그아웃
            else naverLogout();  // 네이버 로그아웃
        }).catch(error => {
            console.log(error);
        })
    }

    const clearSession = () => {
        setSessionID();
        sessionStorage.clear();
    }

    /** 카카오톡 로그아웃 */
    const kakaoLogout = () => {
        window.Kakao?.Auth.logout((e) => {
            window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_KAKAO_KEY}&logout_redirect_uri=${process.env.REACT_APP_FRONT_URL}/login`;
        });
    }

    /** 네이버 로그아웃 */
    const naverLogout = () => {
        // 로그아웃 url 팝업으로 호출 후 바로 닫기
        const logoutPopup = window.open("https://nid.naver.com/nidlogin.logout", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=600,height=1000");
        setTimeout(() => {
            logoutPopup.close();
        }, 200);
    }


    return {
        logout
    }
}