import { useCallback } from 'react';

/**
 * 모바일 - 모달창 오픈 시 바디영역 스크롤 방지
 */
export function useScrollLock() {

  let scrollPosition = 0;

  const lockScroll = useCallback(() => {
    scrollPosition = window.pageYOffset;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%';
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, []);

  return { lockScroll, unlockScroll };
}