import '../assets/css/style.css';

function Service() {

    return (
        <div className='text-content'>
            <h2>
                <strong>이용약관</strong>
            </h2>
            <ul>
                <li>
                    <p> 이용 약관을 상세히 읽어 주시기 바랍니다. 귀하는 본 웹 사이트에 접속하거나 본 웹 사이트의 모든 정보를 수락함으로써 본 약관에 동의합니다.</p>
                </li>
                <li>
                    <p> AIspot은 Stability AI의 Stable Diffusion 모델을 활용하여 AI 콘텐츠를 생성해 주는 서비스입니다.</p>
                </li>
                <li>
                    <p> 이용자는 AI 콘텐츠 기능을 사용하기 위해 회원가입해야 합니다. 회원가입은 네이버, 카카오, 구글 로그인으로 제공되며 서비스는 무료로 제공됩니다.</p>
                </li>
                <li>
                    <p> 이용자는 AIspots가 이메일을 통해 AI 콘텐츠 관련 안내 및 기타 비즈니스 메일을 보낼 수 있다는 데 동의합니다.</p>
                </li>
                <li>
                    <p> 수집된 개인정보는 AI 콘텐츠 생성 서비스 개선을 위해 사용될 수 있으며, 이용자의 동의 없이 제3자에게 제공되지 않습니다. <br />
                        이용자는 AIspots가 이메일을 통해 AI 콘텐츠 관련 안내 및 기타 비즈니스 메일을 보낼 수 있다는데 동의합니다.</p>
                </li>
                <li>
                    <p> AI 모델의 특성상 생성될 콘텐츠의 결과물은 예측할 수 없으며, 이용자가 입력한 프롬프트나 설정과 일치하지 않거나 품질이 떨어질 수 있습니다.</p>
                </li>
                <li>
                    <p> AIspot은 당사의 AI 콘텐츠 생성 서비스를 이용하여 생성된 콘텐츠의 이용으로 인해 발생할 수 있는 모든 문제에 대한 책임을 지지 않습니다. <br />
                        귀하의 법적 관할권이 있는 국가 혹은 대한민국 내에서 법적 문제 소지가 있는 콘텐츠의 생성 시도 및 사용을 금지합니다.</p>
                </li>
                <li>
                    <p> AIspot은 당사의 서비스를 통해 생성된 콘텐츠의 소유권이나 저작권에 대하여 어떠한 주장도 하지 않을 것이며, <br />
                        생성된 콘텐츠는 법적 문제 소지가 없는 한 자유롭게 사용할 수 있습니다.</p>
                </li>
                <li>
                    <p> AIspot에서 생성된 AI 콘텐츠는 AIspot 서버에 저장되지 않으며 생성된 콘텐츠를 보존하려면 이용자의 기기에 저장해야 합니다.</p>
                </li>
                <li>
                    <p> 이용자는 당사의 서비스에 피해를 입힐 수 있는 행위를 해서는 안 됩니다. <br />
                        당사의 서비스에 피해를 입힌다고 판단되면 경고 없이 계정을 차단하거나 법적인 조치를 취할 수 있습니다.</p>
                </li>
                <li>
                    <p> AIspot은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있고, <br />
                        새로운 서비스로의 교체 등 적절하다고 판단하는 사유에 기하여 현재 제공되는 서비스를 이용자에게 사전통지 없이 완전히 변경 또는 중단할 수 있습니다.</p>
                </li>
                <li>
                    <p> 본 사이트에서 AI를 통해 생성된 콘텐츠를 제외한 모든 콘텐츠의 산업 재산권은 AIspot에게 있으며 <br />
                        텍스트, 이미지 및 기타 콘텐츠뿐만 아니라 그래픽 레이아웃은 저작권법에 의해 보호됩니다. <br />
                        이용자는 AIspot을 이용함으로써 얻은 정보를 AIspot의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</p>
                </li>
                <li>
                    <p> AIspot은 약관의규제등에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</p>
                </li>
                <li>
                    <p> AIspot과 이용자 간 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, <br />
                        본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.</p>
                </li>
            </ul>
        </div>
    )
}

export default Service;