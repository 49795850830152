export const DBConfig = {
  name: 'Aispot',
  version: 1,
  objectStoresMeta: [
    {
      store: 'album',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'userID', keypath: 'userID', options: { unique: false } },
        { name: 'base64', keypath: 'base64', options: { unique: false } },
        { name: 'seed', keypath: 'seed', options: { unique: false } },
        { name: 'info', keypath: 'info', options: { unique: false } },
        { name: 'promptTxt', keypath: 'promptTxt', options: { unique: false } },
        { name: 'promptOptions', keypath: 'promptOptions', options: { unique: false } },
        { name: 'nPromptTxt', keypath: 'nPromptTxt', options: { unique: false } },
        { name: 'promptNOptions', keypath: 'promptNOptions', options: { unique: false } },
        { name: 'image_style', keypath: 'image_style', options: { unique: false } },
      ]
    }
  ]
};