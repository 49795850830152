/** 현재 날짜+시간 출력 */
export const getNow = () => {
    const date = new Date();
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const dateTimeString = `${year}${month}${day}_${hours}${minutes}${seconds}`;

    return dateTimeString;
}

/** 이미지 다운로드 (base64 to link) */
export const downloadImage = (base64) => {
    const img = new Image();
    img.src = 'data:image/jpeg;base64,' + base64;

    // Image 객체 로드 후 다운로드
    img.onload = function () {
        // 캔버스 객체 생성
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        // 이미지 그리기
        ctx.drawImage(img, 0, 0);

        // 다운로드 링크 생성
        const link = document.createElement('a');
        link.download = 'Aispot_' + getNow();
        link.href = canvas.toDataURL('image/jpeg');

        // 링크 클릭 다운로드
        link.click();
    };
}


/** 브라우저 DB 사용 가능 여부 체크 (IE 미지원) */
export function isDBSupported() {
    return new Promise((resolve, reject) => {
        // Check for IndexedDB support
        if (!window.indexedDB) {
            console.log("IndexedDB is not supported in this browser.");
            reject("지원되지 않는 브라우저입니다.");
        }

        // Open a connection to IndexedDB and get the storage information
        const request = window.indexedDB.open("Aispot");
        request.onsuccess = function (event) {

            const db = event.target.result;
            const storage = navigator.storage;

            if (navigator.storage && navigator.storage.estimate) {
                // Get the storage estimate
                storage.estimate().then(function (estimate) {
                    const total = estimate.quota / 1024 / 1024 / 1024; // 총 용량 (GB)
                    const used = estimate.usage / 1024 / 1024 / 1024; // 사용 중인 용량 (GB)
                    const remaining = (total - used).toFixed(2); // 남은 용량 (GB)
// console.log("용량체크 :"+ used, remaining, total );
                    if (remaining < 0.1) {  // 1기가 미만
                        alert('브라우저에 저장공간이 희박합니다. 계속 저장할시에 브라우저가 정상적으로 작동하지 않을 수 있습니다.\n저장공간을 확보하신후 이용해주시기 바랍니다.')
                      //  reject('기기 용량이 부족하여 저장할 수 없습니다.');
                    // } else if (remaining < 1) {  // 10기가 미만
                    //    alert('기기 용량이 부족하니 하드디스크 용량을 확보해주세요.');
                     }
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        };
    });
}



/** base64 변환 */
export const convertBase64 = (base64) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = 'data:image/png;base64,' + base64;

        // Image 객체 로드 후 다운로드
        img.onload = function () {
            // 캔버스 객체 생성
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');

            // 이미지 그리기
            ctx.drawImage(img, 0, 0);

            // JPEG 형식으로 인코딩
            const jpegDataUrl = canvas.toDataURL('image/jpeg');
            const jpegBase64String = jpegDataUrl.replace(/^data:image\/(png|jpeg);base64,/, "");

            resolve(jpegBase64String);
        };
    });
};