import { useEffect, useRef, useState } from "react";
import { ReactComponent as DownIcon } from '../assets/svg/icon_down.svg';

/** 커스텀 셀렉트박스 */
function CustomSelectBox({ options, onChange, selectedValue }) {

    const selectRef = useRef(null);  // 셀렉트 ref
    const [isOpen, setIsOpen] = useState(false);  // 셀렉트 옵션 오픈 상태

    // 셀렉트 클릭
    const handleSelectClick = () => {
        setIsOpen(!isOpen);
    };

    // 옵션 선택
    const handleOptionClick = (option) => {
        setIsOpen(false);
        onChange(option);
    };

    // 셀렉트 이외 영역 클릭 시 셀렉트 옵션 닫음
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRef]);

    return (
        <div className={`custom-select-box ${isOpen ? 'open' : ''}`} ref={selectRef} onClick={handleSelectClick}>
            <div className="custom-select-box__selected">
                {selectedValue || options[0].label}
            </div>
            {isOpen && (
                <div className="custom-select-box__options">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`custom-select-box__option ${(selectedValue === option.value) && 'selected'}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
            <DownIcon />
        </div>
    );
}

export default CustomSelectBox;